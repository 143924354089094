









































import Vue from 'vue'
import { mapActions } from 'vuex'
import { VFormRef } from '@/types'
import { onLoggedOut } from '@/mixins/UseAuth'
import { getValidators } from '@/mixins/FormMixin'
import { MetaInfo } from 'vue-meta'
import { APP_META } from '@/consts'

export default Vue.extend({
	name: 'AccountDeletion',
	metaInfo(): MetaInfo {
		return {
			...APP_META,
			title: `Delete Your Account | ${APP_META.title}`,
		}
	},
	data: () => ({
		rules: getValidators('required', 'password'),
		isLoading: false,
		isFormValid: false,
		user: {
			password: '',
		},
	}),
	methods: {
		...mapActions('auth', ['deleteAccount', 'setAuthState']),
		async onDeleteAccount() {
			if (!(this.$refs.formRef as VFormRef).validate()) return

			this.isLoading = true
			let [err] = await this.deleteAccount(this.user)
			this.isLoading = false

			if (err) return this.$toast.error('Error updating password')

			this.$toast.success('Password updated successfully')
			onLoggedOut()
			this.setAuthState({
				authentication: {
					dialog: true,
					persistent: true,
					currentTab: 'signin',
				},
			})
		},
	},
})
